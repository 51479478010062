import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <>
      {/* banner */}
      <section className="page-banner bg_cover p-r z-1 " style={{backgroundImage: 'url(assets/images/bg/footer-bg-1.webp)' }}>
            
            <div className="container ">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="page-title">
                            <h1>About us</h1>
                            <ul className="breadcrumbs-link">
                                <li><Link to="/">Home</Link></li>
                                <li className="active">About us.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      {/* banner */}

      <section className="about-section pt-60 pb-60">
        <div className="container">
          <div className="row">
          <div className="col-lg-6">
              <div className="about-three-image_box p-r mb-40 wow fadeInLeft mt-20">
                <div className="row align-items-center">
                  <div className="col-md-12 fadein">
                  
                    <img
                      src="assets/images/about/team.webp"
                      className="about-img_one"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-three-content_box pl-lg-10">
                <div className="section-title section-title-left pt-10 mb-15 wow fadeInUp">
                  <h2>
                    Why Choose US ?<br />
                    <strong style={{ color: "#0192d8" }}>
                      {" "}
                      Unisafe Digital
                    </strong>
                  </h2>
                </div>
                <p className="text-dark text-justify">
                We are startups in the service industry business. It was lunched in the year 2015 by two partners with a dedicated team of service technicians, to provide maximum customer satisfaction.


                </p>
                <p className="text-dark text-justify">
                With the moto of service and goal for almost customer satisfaction, we have over the years achieved greater heights in the field and have a dedicated satisfied customer base. The product range that we cater to is in the electronics security and surveillance and roof top solar as well as power invertors and batteries. Details of the product range is as mentioned below.
               </p>
                <p className="text-dark text-justify">
                Looking forward to a long and healthy association and a chance to serve you.</p>
                
              </div>
            </div>
            
                  </div>
                  <div className="row">
                  <div className=" col-lg-2 col-md-6 col-6">
                    <div className="food-card text-center mb-40 wow fadeInDown">
                      <img src="assets/images/icon/c1.webp" alt="" />

                      <div className="text">
                        <h5>Camera</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-6 col-6">
                    <div className="food-card text-center mb-40 wow fadeInUp">
                      <div className="icon">
                        <img src="assets/images/icon/c2.webp" alt="" />
                      </div>
                      <div className="text">
                        <h5>Biometric </h5>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-2 col-md-6 col-6">
                    <div className="food-card text-center mb-40 wow fadeInDown">
                      <div className="icon">
                        <img src="assets/images/icon/c3.webp" alt="" />
                      </div>
                      <div className="text">
                        <h5>VDP</h5>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-2 col-md-6 col-6">
                    <div className="food-card text-center mb-40 wow fadeInDown">
                      <div className="icon">
                        <img src="assets/images/icon/c4.webp" alt="" />
                      </div>
                      <div className="text">
                        <h5>Solar</h5>
                      </div>
                    </div>
                  </div>
                  <div className=" col-lg-2 col-md-6 col-6">
                    <div className="food-card text-center mb-40 wow fadeInDown">
                      <div className="icon">
                        <img src="assets/images/icon/c5.webp" alt="" />
                      </div>
                      <div className="text">
                        <h5>Batteries</h5>
                      </div>
                    </div>
                      </div>
                      <div className="col-lg-2 col-md-6 col-6">
                    <div className="food-card text-center mb-40 wow fadeInDown">
                      <div className="icon">
                        <img src="assets/images/icon/c6.webp" alt="" />
                      </div>
                      <div className="text">
                        <h5>Inverter</h5>
                      </div>
                      
                    </div>
                  </div>
                </div>
        </div>
      </section>
    </>
  );
}
