import React from "react";
import { Link } from "react-router-dom";

export default function Products() {
  return (
    <>
      
     {/* banner */}
     <section className="page-banner bg_cover p-r z-1 " style={{backgroundImage: 'url(assets/images/bg/footer-bg-1.webp)' }}>
            
            <div className="container ">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="page-title">
                            <h1>Products </h1>
                            <ul className="breadcrumbs-link">
                                <li><Link to="/">Home</Link></li>
                                <li className="active">Products </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      {/* banner */}
     
      <section className="">
     <div className="project-grid-page pt-50 pb-50 " >
        <div className="container">
        
        
        <h1 className="heading">We are Provide Many Products & Services</h1>
        
        </div>
        </div>
     </section>
      <section
        className="project-grid-page p-r z-1 pt-50 pb-30"
        id="project-filter"
      >
        <div className="container">
          
          <div className="row project-row">
            <div className="col-lg-3 col-md-6 col-6 project-column cat-1">
              <div className="project-item-three mb-30 wow fadeInUp">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/camera/1.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-6 project-column cat-2">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/camera/2.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-3">
              <div className="project-item-three mb-30 wow fadeInUp">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/camera/3.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-4">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/camera/4.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-5">
              <div className="project-item-three mb-30 wow fadeInUp">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/attenden_machine/1.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-6 cat-1">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/attenden_machine/2.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-3 cat-6">
              <div className="project-item-three mb-30 wow fadeInUp">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/attenden_machine/3.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-4">
              <div className="project-item-three mb-30 wow fadeInUp">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/attenden_machine/4.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-6 project-column cat-2">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/solar/1.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-2">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/solar/2.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-2">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/solar/3.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-2">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/solar/4.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-2">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/batteries/1.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-2">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/batteries/2.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-2">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/batteries/3.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 project-column cat-2">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-3">
                  <img src="assets/images/products/batteries/4.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      {/* <h3 className="title">
                        <a href="portfolio-details.html"></a>
                      </h3> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
