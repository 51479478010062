import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <>
      <header className="header-area">
        <div className="top-bar-one dark-black-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-12 col-md-12 col-7">
                <div className="top-left d-flex align-items-center">
                  <span className="text text-white">
                    Unisafe Digital & Solar Energy
                  </span>
                  <span
                    className="lang-dropdown text-white"
                    style={{ textTransform: "uppercase" }}
                  >
                    Safety is not Expensive.It is Priceless
                  </span>
                  {/* <span className="test ">Safety is not Expensive.It is Priceless</span> */}
                </div>
              </div>
              <div className="col-xl-5 col-lg-12 col-md-12 col-5">
                <div className="top-right">
                  <span className="text text-white">
                    <i className="flaticon-phone-call text-white"></i>
                    <a href="tel:7340044005">+91 73-4004-4005</a>
                  </span>
                  <ul className="social-link">
                    <li>
                      <a
                        href="https://www.facebook.com/UNISAFEDIGITAL"
                        target="blank"
                      >
                        <i>
                          <img
                            src="assets/images/icon/facebook_Logo.webp"
                            alt="facebook"
                            width="25px"
                          />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/unisafedigital/"
                        className="text-white"
                        target="blank"
                      >
                        <i>
                          <img
                            src="assets/images/icon/Instagram_logo.webp"
                            width="25px"
                            alt=""
                          />
                        </i>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://api.whatsapp.com/send?phone=7340044005&text=Hello this is the starting message"
                        target="blank"
                        className="text-white"
                      >
                        <i>
                          <img
                            src="assets/images/icon/icon.webp"
                            alt=""
                            width="25px"
                          />
                        </i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-navigation navigation-two">
          <div className="nav-overlay"></div>
          <div className="container">
            <div className="primary-menu">
              <div className="site-branding ">
                <Link to="/" className="brand-logo">
                  <img
                    src="assets/images/logo/logo.webp"
                    width="100%"
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="nav-inner-menu">
                <div className="nav-menu">
                  {/* <!-- Navbar Close --> */}
                  <div className="navbar-close">
                    <i className="far fa-times"></i>
                  </div>
                  {/* <!-- Navbar Call Button --> */}
                  <div className="call-button">
                    <span>
                      <i className="flaticon-phone-call"></i>
                      <a href="tel:+91 7340044005">+91 73-4004-4005</a>
                    </span>
                  </div>
                  <nav className="main-menu">
                    <ul>
                      <li className="menu-item">
                        <Link to="/" className="active">
                          Home
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/products">Products</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/gallary">Gallary</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/contactUs">Contact Us</Link>
                      </li>
                    </ul>
                  </nav>
                  {/* <!-- Navbar Menu Button --> */}
                </div>

                <div className="nav-right-item d-flex align-items-center">
                  <div className="navbar-toggler">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
              {/* <div className="ds">
                <div>
                  <img src="/assets/images/logo/happy_new_year_2024.gif" alt="happy_new_year_2024" width={130} />
                </div>
              </div> */}
              {/* diwali */}

              {/* <div className="mobd">
              <img src="assets/images/diwali/indian-rocket1.gif" alt="" width={'6%'} style={{position:'absolute'}} />
              </div> */}
              {/* mobile */}
              {/* <div className="deskd">
                <div className="wrapper">
                  <div className="flag">
                    <div className="wave"></div>
                    <div className="top1"></div>
                    <div className="middle">
                      <div className="wheel">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div className="bottom"></div>
                  </div>
                </div>
              </div> */}
              {/*mobile */}

              {/* desktop */}
              {/* <div className="mobd">
              <div className="wrapper">
                  <div className="flag">
                    <div className="wave"></div>
                    <div className="top1"></div>
                    <div className="middle">
                      <div className="wheel">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div className="bottom"></div>
                  </div>
                </div>

              </div> */}
              {/* desktop */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
