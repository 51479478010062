import Footer from './com/Footer';
import Header from './com/Header';
import Home from './com/Home';
import{Routes,Route} from 'react-router-dom'
import About from './com/About';
import ContactUs from './com/ContactUs';
import Products from './com/Products';
import Thankyou from './com/Thankyou';
import Gallary from './com/Gallary';
import NoMatch from './com/NoMatch';



function App() {
  return (
    <>
      <Header/>
      <Routes>
      <Route path="*" element={<NoMatch/>} />
        <Route path="/" element={<Home />}/>
        <Route path="about" element={<About />} />
        <Route path="contactUs" element={<ContactUs />} />
        <Route path="products" element={<Products />} />
        <Route path="gallary" element={<Gallary />} />
        <Route path="thankyou" element={<Thankyou />} />
        
       
    </Routes>
    
     <Footer/>
    </>
  );
}

export default App;
