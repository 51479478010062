import React from "react";
import { CarouselItem } from "react-bootstrap";
// import { CarouselItem } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
export default function Home() {
  return (
    <>
      {/* slider */}
      <div className="ds">
      <Carousel fade>

      <CarouselItem>
        <img
        className="d-block w-100" 
        src="assets/images/slider/slider11.webp" alt=""
        
        // width={'1526px'}
        // height={'672px'}

         />
        
      </CarouselItem>
     
      <Carousel.Item>
      <img
          className="d-block w-100"
  
          // src="assets/images/bg_img/bg1.jpg"
          src="assets/images/slider/scan-fingerprint-biometric.jpg"
          alt="First slide"
          // width={'1526px'}
          // height={'672px'}
        />
      </Carousel.Item>

      <Carousel.Item>
      <img
          className="d-block w-100"
  
          // src="assets/images/bg_img/bg1.jpg"
          src="assets/images/slider/slider7.webp"
          alt="First slide"
          width={'1526px'}
          height={'672px'}
        />
      </Carousel.Item>
      
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/images/slider/slider22.webp"
          alt="Third slide"
          width={'1526px'}
          height={'672px'}
        />

          </Carousel.Item>
          <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/images/slider/slider33.webp"
          alt="Third slide"
          width={'1526px'}
          height={'672px'}
        />

      </Carousel.Item>
        </Carousel>
        </div>



      {/* slider */}

       {/* mobile slider */}
       <div className="ms">
       <Carousel fade>
       

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/images/slider/sm1.webp"
          alt="First slide"
        />
        
      </Carousel.Item>
      
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/images/slider/sm2.webp"
          alt="Second slide"
        />

      
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/images/slider/sm3.webp"
          alt="Third slide"
        />
        

        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/images/slider/sm55.gif"
          alt="Third slide"
        />
        

        <Carousel.Caption>
          
        </Carousel.Caption>
      </Carousel.Item>
      
        </Carousel>
        </div>



      {/*mobile slider */}



      {/* productes */}

    {/* <section>
    <div className="bgc">
        <h1 className="hading"></h1>
      </div>
    </section> */}

      
      <section className="about-section pt-100">
        <div className="container">
          <div className="row">
          <div className="col-lg-6">
              <div className="about-three-image_box p-r mb-40 wow fadeInLeft mt-20">
                <div className="row align-items-center">
                  <div className="col-md-12 fadein">
                  
                    <img
                      src="assets/images/about/team.webp"
                      className="about-img_one"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-three-content_box pl-lg-10">
                <div className="section-title section-title-left pt-10 mb-15 wow fadeInUp">
                  <h2>
                    About Us
                    <strong style={{ color: "#0192d8" }}>
                      Unisafe Digital
                    </strong>
                  </h2>
                </div>
                <p className="text-dark text-justify">
                We Are Startups In The Service Industry Business. It Was Lunched In The Year 2015 By Two Partners With A Dedicated Team Of Service Technicians, To Provide Maximum Customer Satisfaction.


                </p>
                <p className="text-dark text-justify">
                With The Moto Of Service And Goal For Almost Customer Satisfaction, We Have Over The Years Achieved Greater Heights In The Field And Have A Dedicated Satisfied Customer Base. The Product Range That We Cater To Is In The Electronics Security And Surveillance And Roof Top Solar As Well As Power Invertors And Batteries. Details Of The Product Range Is As Mentioned Below.
                </p>
                <p className="text-dark text-justify">
                Looking Forward To A Long And Healthy Association And A Chance To Serve You.
                </p>
                
              </div>
            </div>
            
            </div>         
        </div>
      </section>
     
     <section className="">
     <div className="project-grid-page pt-50 pb-70" >
        <div className="container">
        {/* <div className="img2114 ds">
        <img src="assets/images/bg_img/bg7.gif" alt="" />
        </div> */}
        <div className="ds">
        <h1 className="heading section-title">We are Provide Many Products & Services</h1>
        </div>

        <div className="ms">
        <h1 className="heading-2">We are Provide Many Products & Services</h1>
        </div>
        {/* <div className="img21142 ds">
          <img src="assets/images/bg_img/bg7.gif" alt="" />
        </div> */}
        </div>
        </div>
     </section>


      <section> 

      <div className="project-grid-page p-r z-1 pt-60 pb-60 " id="project-filter" >
        <div className="container">
           <div className="row project-row">
            <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-1">
              <div className="project-item-three mb-30 wow fadeInUp">
                <div className="img-holder cat-2">
                  <img src="assets/images/services/s1.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      <h3 className="title">
                        <a href="/">CCTV</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <h6 className="mt-30 text-center">CCTV Surveillance System</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-2">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-2">
                  <img src="assets/images/services/s2.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      <h3 className="title">
                        <a href="/">ATTENDANCE MACHINE</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <h6 className="mt-30 text-center">Access Control & Time Attendance Machine</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-3">
              <div className="project-item-three mb-30 wow fadeInUp">
                <div className="img-holder cat-2">
                  <img src="assets/images/services/s3.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      <h3 className="title">
                        <a href="/">VIDEO DOOR PHONE</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <h6 className="mt-30 text-center">Video Door Phone and Electronic Lock</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-4">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-2">
                  <img src="assets/images/services/s4.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      <h3 className="title">
                        <a href="/">ALARM SYSTEM</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <h6 className="mt-30 text-center">Home Alarm System</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-5">
              <div className="project-item-three mb-30 wow fadeInUp">
                <div className="img-holder cat-2">
                  <img src="assets/images/services/s5.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      <h3 className="title">
                        <a href="/">FIRE ALARM</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <h6 className="mt-30 text-center">Fire Alarm System</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-6">
              <div className="project-item-three mb-30 wow fadeInDown">
                <div className="img-holder cat-2">
                  <img src="assets/images/services/s6.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      <h3 className="title">
                        <a href="/">INTERCOM</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <h6 className="mt-30 text-center">Intercom & EPABX</h6>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-7">
              <div className="project-item-three mb-30 wow fadeInUp">
                <div className="img-holder cat-2">
                  <img src="assets/images/services/s7.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content ">
                      <h3 className="title">
                        <a href="/">SOLAR PLANT</a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="mt-30 text-center">Roof Top Solar Plant</h6>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 project-column cat-8">
              <div className="project-item-three mb-30 wow fadeInUp">
                <div className="img-holder cat-2">
                  <img src="assets/images/services/s8.webp" alt="" />
                  <div className="hover-portfolio">
                    <div className="hover-content">
                      <h3 className="title">
                        <a href="/">BATTERIES BACKUP</a>
                      </h3>
                    </div>
                  </div>
                </div>
                <h6 className="mt-30 text-center">Invertors and Batteries Backup</h6>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
        
      <section className="pt-50 pb-50">
        <div className="container text-center pb-30 section-title ">
          <h2 className="title customheading">Best brands for CCTV Camera and Solar & Batteries</h2>
          
          <h6 className="pt-20">Unisafe Digital Provide The Best Company Products (CPPLUS,HIKVISION,DAHUA,LUMINOUS)</h6>
          
        </div>
      </section>

      <section className=" pb-30">
        <div className="container">
          <div className="row">
            <div className=" col-lg-3 col-md-6 col-6">
              <div className="food-card text-center mb-40 wow fadeInDown">
                <img src="assets/images/partner/1.webp" alt="" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="food-card text-center mb-40 wow fadeInUp">
                <div className="icon">
                  <img src="assets/images/partner/2.webp" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="food-card text-center mb-40 wow fadeInDown">
                <div className="icon">
                  <img src="assets/images/partner/3.webp" alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6">
              <div className="food-card text-center mb-40 wow fadeInDown">
                <div className="icon">
                  <img src="assets/images/partner/4.webp" alt="" />
                </div>
              </div>
            </div>
           
            
           
          </div>
        </div>
      </section>

     
    </>
  );
}
