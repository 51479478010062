import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <a
        href="tel:+917340044005"
        className="fa fa-phone"
        id="call"
        rel="nofollow"
        target="blank"
      >
        
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=7340044005&text=Hello"
        id="whatsapp"
        className="fa fa-whatsapp"
        rel="nofollow"
        target="blank"
      >
        
      </a>
      {/* <a className="btn btn-default1 float-right" href="tel:+91 9929558061" <i className='fa fa-phone' aria-hidden="true"> 9929558061   */}

      <footer
        className="footer-bg bg_cover p-r z-1 footer-white"
        style={{ backgroundImage: "url(assets/images/bg/footer-bg-1.webp)" }}
      >
        <div className="container">
          <div className="footer-widget pt-70 pb-15">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget about-widget mb-40 wow fadeInDown">
                  <div className="footer-about-content">
                    <div className="demo1">
                      <img src="assets/images/logo/logo(251x81).webp" alt="" />
                    </div>

                    <div className="social-box">
                      <h5 className="mb-15">Follow Now</h5>
                      <ul className="social-link">
                        <li>
                          <a
                            href="https://www.facebook.com/UNISAFEDIGITAL"
                            target="blank"
                            className="text-white"
                          >
                            <i>
                              <img
                                src="assets/images/icon/facebook_Logo.webp"
                                alt="facebook"
                              />
                            </i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/unisafedigital/
"
                            className="text-white"
                            target="blank"
                          >
                            <i>
                              <img
                                src="assets/images/icon/Instagram_logo.webp"
                                alt="instagram"
                              ></img>
                            </i>
                          </a>
                        </li>

                        {/* <li><a href="/"><i className="fab fa-twitter"></i></a></li> */}
                        {/* <li><a href="/"><i className="fab fa-linkedin"></i></a></li> */}
                        <li>
                          <a href="/">
                            <i>
                              <img
                                src="assets/images/icon/youtube.webp"
                                alt=""
                              />
                            </i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget footer-nav-widget mb-40 wow fadeInUp">
                  <h4 className="widget-title">Quick Links</h4>
                  <div className="footer-widget-nav">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/contactUs">Contact Us</Link>
                      </li>
                      <li>
                        <Link to="/products">Products</Link>
                      </li>
                      <li>
                        <Link to="/Gallary">Gallary</Link>
                      </li>
                      <li>
                        {" "}
                        <a
                          href="https://forms.gle/hi4gh9pChUEwVcLt7"
                          target="new"
                        >
                          Register complaint
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget recent-post-widget mb-40 wow fadeInDown">
                  <h4 className="widget-title">Products</h4>
                  <div className="footer-widget-nav">
                    <ul>
                      <li>
                        <Link to="/products">CCTV surveillance system</Link>
                      </li>
                      <li>
                        <Link to="/products">Roof top Solar Plant</Link>
                      </li>
                      <li>
                        <Link to="/products">
                          Invertors and Batteries Backup
                        </Link>
                      </li>
                      <li>
                        <Link to="/products">Intercom & EPABX</Link>
                      </li>
                      <li>
                        <Link to="/products">Fire Alarm System</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="footer-widget recent-post-widget mb-40 wow fadeInDown">
                  <h4 className="widget-title">Contact Us</h4>
                  <div className="footer-about-content">
                    <address className="c-w">
                      G-23,B14 Shree mansion,kamla marg,C-Scheme,Jaipur
                    </address>
                    {/* <p>G-23,B14 Shree mansion,kamla marg<br/>C-Scheme,Jaipur</p> */}

                    <p>
                      <i className="flaticon-email text-white"></i>{" "}
                      <a href="mailto:unisafesolarenergy@gmail.com">
                        {" "}
                        unisafesolarenergy@gmail.com
                      </a>{" "}
                    </p>
                    <p>
                      <i className="flaticon-email text-white"></i>{" "}
                      <a href="mailto:unisafediginsolar@gmail.com">
                        {" "}
                        unisafediginsolar@gmail.com
                      </a>{" "}
                    </p>
                    <p>
                      <i className="flaticon-phone-call text-white"></i>{" "}
                      <a href="tel:917340044005">&nbsp; 73-4004-4005 </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Fimg --> */}

          <div className="footer-copyright">
            <div className="col-lg-12">
              <div className="copyright-text text-center">
                <p>
                  &copy; Copyright 2015 All Rights Reserved | Designed and
                  Developed by{" "}
                  <a href="http://www.updeshprogrammer.in/" target="blank">
                    {" "}
                    updeshprogrammer.in{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
